.footer{
width: 100vw;
height: 8rem;
background: rgb(40,40,40);
display: flex;
justify-content: center;
align-items: center;
font-weight: 300;
font-size: small;
}
.icon{
display: flex;
flex-direction: row !important;
object-fit: cover;
width: 60px;
height:60px;
 
min-width: 3.5rem;
margin: autu;
padding: 0.25rem;
}
.footer__logo{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.img{
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}