#works{
margin: 0 auto;
min-height: calc(100vh - 4rem);
width: 100vw;
max-width:60rem;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
padding: 0 1rem;

}
.button{
    color: black;
}
.workstitle{
    color: white;
    font-size: 3.5rem;
    font-weight: 600;
    text-align: center;
}
.worksDesc{
    color: white;
    font-size: 1.5rem ;
    font-weight: 300;
    text-align: center;
    margin-bottom: 5%;
}

.titleCard{
    color: white;
    font-size: 1.5rem;
    font-weight: 400;
    
   
  }

.worksImgs{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap:25px
}
