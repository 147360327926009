.contactTitle{
    margin-top: 2%;
    color: white;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    
}

.contactDesc{
    text-align: center;
    display: flex;
    justify-content: center;
    color: white;
    font-size: 1.5rem ;
    font-weight: 300;
    
}

.contactForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    margin: 1rem auto;
    max-width: 60rem;
}
.name,.email,.message{
 
  font-size: medium;
  width: 100%;
  max-width: 50rem;
  margin:0.5rem;
  padding: 0.5rem 1rem;
  color: white;
  border: none;
  border-radius: 0.5rem;
  background: rgb(40,40,40);
}


.contactFormButton{
    background: white;
    border: none;
    border-radius: 0.5rem;
    margin: 2rem;
    padding: 0.75rem 3.5rem;
    color: black;
}