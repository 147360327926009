#intro {
  height: 100vh;
  width: 100%;
  max-width: 110rem;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  position: relative;
}

.bg {
  width: 20%;
  height: 60%;
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  z-index: -1;

}

.introContent {
  height: 100%;
  width: 100%;
  padding: 2rem;
  font-size: 5rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}
.active {
  color: yellow;
  border-bottom: 3px solid yellow;
  padding-bottom: 0.5rem;
}
.hello {
  font-size: 3rem;
  font-weight: 100;

  
}

.introName {
  color: yellow;
}

.introPara {
  font-size: large;
  font-weight: 300;
}

.btn {
  color: black;
  background: white;
  margin: 1rem 0;
  padding: 0.75rem 2rem;
  border-radius: 2rem;
}

.btnImg {
  object-fit: cover;
  margin: 0 0.25rem;
  height: 1rem;
}


@media screen and (max-width: 1220px) {
  .bg {
    width: 40%;
    height: 30%;
    position: absolute;
    top:14%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    z-index: -1;
    
  }
  .introContent {
    font-size: 9vw;
  }
  .hello {
    font-size: 7vw;
  }
  .navbar {
    font-size: 1rem;
    width: 0%;
  }
}


