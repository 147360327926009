/* Reset some default styles */
body, h1, h2, h3, p, ul, li {
    margin: 0;
    padding: 0;
  }
  
  /* Set box-sizing to border-box for easier layout calculations */
  * {
    box-sizing: border-box;
  }
  
  /* Style the skills section */
  #skills {
    background-color: rgb(30, 30, 30); /* Light gray background color */
    padding: 80px 0; /* Adjust the padding as needed */
  }

  .skillspara{
    color: white;
    font-size: 1.5rem ;
    font-weight: 300;
    text-align: center;
  }
  
  .mySkillsPara{
   
    color: white;
    font-size: 3.5rem;
    font-weight: 600;
    text-align: center;
  }
  
  .container {
    max-width: 80%;
    margin: 0 auto;
  }
  
  .section-title {
    text-align: center;

  }
  
  /* Style the skills marquee */
  .marquee {
    overflow: hidden;
    margin: 40px 0; /* Add margin as needed */
  }
  
  .marquee-content {
    display: flex;
    animation: marquee 35s linear infinite;
  }
  
  .slick-slide {
    margin: 0 10px;
  }

  .imgSkills{
    width: 7%;
    height: 8%;
   
}

  @media screen and (max-width: 1220px) {
    .imgSkills{
        width: 25%;
        height: 15%;
       
    }
  }
  
  /* Keyframes for marquee animation */
  @keyframes marquee {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  