.navbar{
background-color: rgb(30, 30, 30);
height: 5rem;
width: 100%;
margin: 1 1;
padding: 1rem 2rem;
display: flex;
align-items: center;
justify-content: center;
position: sticky;
top: 0;
z-index: 3;
}

.desktopMenuListItem{
font-size: 1.1rem;
margin:2rem;
cursor: pointer;
}
.desktopMenuListItem:hover{
    color: yellow;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid yellow;
}

.desktopMenuImg{
    object-fit: cover;
    height: 1rem;
    width: 1rem;
    margin:1rem;
}

.menuIcon{
    display: none;
    cursor: pointer;
}

/* Add this to your CSS */
@media screen and (max-width: 1220px) {
   .menuIcon{
    display: block;
    background:transparent;
   }
   .desktopMenuListItem{
    display: none;
    background:transparent;
   }
    .desktopMenu{
    background:transparent;
     width: 30px;
     height: 30px;
    }
    .navbar{
        background:transparent;
        margin-left: 10px;
    }
    .navbar.open {
        display: block;
        .desktopMenuListItem {
            display: block; /* Set display to block when navbar is open */
            background: rgb(40,40, 40);
          }
          .desktopMenu{
            background: rgb(40,40, 40);
            width: 350px;
            height: 400px;
            border-radius: 2rem;
            } 
        .navbar{
            margin-left: 50px;
        }
            
      }
}
